<mat-form-field class="small-padding" appearance="fill">
  <mat-label>LHOST</mat-label>
  <input matInput 
         class="local-host-input"
         [(ngModel)]="localHost"
         (input)="onLocalHostChange()">
</mat-form-field>
<mat-form-field class="small-padding" appearance="fill">
  <mat-label>LPORT</mat-label>
  <input matInput
         class='local-port-input'
         type="number"
         [(ngModel)]="localPort"
         (input)="updateOutputPayload()">
</mat-form-field>
<mat-form-field class="small-padding wide-input" appearance="fill">
  <mat-label>Payload</mat-label>
  <mat-select class="payload-selector"
              [(ngModel)]="payload"
              (selectionChange)="updateOutputPayload()">
    <mat-option *ngFor="let payload of availablePayloads"
                [value]="payload">
      {{payload.name}}
    </mat-option>
  </mat-select>
</mat-form-field>
<div class="custom-input-box">
  <div>
    <label class="custom-input-label" 
           id="formats-radio-group-label">
      Formats:
    </label>
  </div>
  <mat-radio-group aria-labelledby="formats-radio-group-label"
                  class="formats-radio-group"
                  [(ngModel)]="selectedFormat"
                  (ngModelChange)="updateOutputPayload()">
    <mat-radio-button *ngFor="let format of availableFormats" 
                      [value]="format"
                      class="format-radio-button">
      {{format}}
    </mat-radio-button>
  </mat-radio-group>
</div>
<div class="custom-input-box">
  <div>
    <label class="custom-input-label" 
           id="encrypt-input-label">
      Encrypt:
    </label>
  </div>
  <mat-checkbox [(ngModel)]="payload.xorEncrypt">XOR</mat-checkbox>
  <input *ngIf="payload.xorEncrypt"
         type="text"
         [(ngModel)]="payload.xorKey"
         (ngModelChange)="updateOutputPayload()"
         class="distance-left">
  <button *ngIf="payload.xorKey"
          [cdkCopyToClipboard]="payload.xorKey"
          class="copy-to-clipboard-button distance-left"
          color="primary"
          aria-label="Copy xor key to clipboard">
   <mat-icon class="copy-key-icon">content_copy</mat-icon>
  </button>
</div>
<div>
  <button [cdkCopyToClipboard]="outputPayload"
          class="copy-to-clipboard-button"
          color="primary"
          aria-label="Copy payload to clipboard">
    <mat-icon>content_copy</mat-icon>
  </button>
</div>
<textarea readonly
          [ngModel]="outputPayload"
          class="output-payload-textarea">
  {{outputPayload}}
</textarea>