import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ClipboardModule} from '@angular/cdk/clipboard'; 
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox'; 
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToolsComponent} from './tools.component';
import {ShellOMaticComponent} from './shell-o-matic/shell-o-matic.component';
import { PayloadOMaticComponent } from './payload-o-matic/payload-o-matic.component';

@NgModule({
  declarations: [
    ToolsComponent,
    ShellOMaticComponent,
    PayloadOMaticComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ClipboardModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatTabsModule,    
  ],
  providers: [],
})
export class ToolsModule { }
