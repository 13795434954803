import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ToolsModule} from './tools/tools.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    ToolsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
