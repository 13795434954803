import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {ToolsComponent} from './tools/tools.component';

const routes: Routes = [
  {path: '', component: ToolsComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
