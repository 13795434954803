<mat-form-field appearance="fill">
  <mat-label>LHOST</mat-label>
  <input matInput 
         class="lhost-input"
         [(ngModel)]="localHost"
         (input)="updateOutputShell()">
</mat-form-field>
<mat-form-field appearance="fill">
  <mat-label>LPORT</mat-label>
  <input matInput
         class='lport-input'
         type="number"
         [(ngModel)]="localPort"
         (input)="updateOutputShell()">
</mat-form-field>
<mat-form-field appearance="fill">
  <mat-label>Flavour</mat-label>
  <mat-select class="shell-flavour-selector"
              [(ngModel)]="shellFlavour"
              (selectionChange)="updateOutputShell()">
    <mat-option *ngFor="let reverseShell of availableReverseShells"
                [value]="reverseShell">
      {{reverseShell}}
    </mat-option>
  </mat-select>
</mat-form-field>

<div *ngFor="let outputShell of outputShells, let i = index">
  <div>
    <button [cdkCopyToClipboard]="outputShells[i]"
            class="copy-to-clipboard-button"
            color="primary"
            aria-label="Copy reverse shell command to clipboard">
      <mat-icon>content_copy</mat-icon>
    </button>
  </div>
  <textarea readonly
            [ngModel]="outputShells[i]"
            class="reverse-shell">
    {{outputShells[i]}}
  </textarea>
</div>

